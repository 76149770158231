@use '@unisporkal/alliance-ui-theme/dist/styles/iStock' as iStockTheme;
@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_functions';

.badge {
  margin-top: 32px;
  background-color: commonStyles.$true-white;
  color: iStockTheme.$is-black;
  border-radius: 16px;
  padding: 8px 16px;
  display: none;

  @media #{$istock-large-up} {
    margin: 0;
    display: block;
  }
}

.title {
  font-size: rem(24);

  @media #{$istock-medium-up} {
    font-size: rem(32);
  }

  @media #{$istock-large-up} {
    font-size: rem(38);
  }
}

.subtitleContainer {
  margin-bottom: 15px;
}

.subtitle {
  font-size: rem(16);

  @media #{$istock-large-up} {
    font-size: rem(21);
  }
}

.note {
  // Assuming the note text color is static, no dynamic styles needed
  color: commonStyles.$true-white;
}

.searchBarContainer {
  position: absolute;
  width: 95%;
  top: 0;

  @media #{$istock-large-up} {
    height: 1rem; // prevent 'jump' on scroll
    top: 1rem;
  }

  .docked {
    @media #{$istock-medium-up} {
      max-width: 100%;
    }
  }
}
