@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints';

.container {
  width: 100%;
  height: 372px;
  gap: 16px;
  text-align: center;
  position: relative;
  background-position: center;
  background-size: cover;
  padding: 16px 12%;
  padding-top: 64px;

  @media #{$istock-medium-up} {
    height: 496px;
  }
}
