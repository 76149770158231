@use '~@unisporkal/alliance-ui-theme/dist/styles/iStock/index' as iStockTheme;
@use '~@unisporkal/alliance-ui-theme/dist/styles/common/index' as commonStyles;

// avoid using @import in favor of @use as syntax as this will prevent collisions
// avoid use of legacy unisporkal-styles in favor of alliance
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints';

// avoid using @import in favor of @use as syntax as this will prevent collisions
// avoid use of legacy unisporkal-styles in favor of alliance
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_functions';

.container {
  width: 100%;
  height: 372px;
  gap: 16px;
  text-align: center;
  background-image: url('https://static.istockphoto.com/display-sets/istock/ai-landing/home-page-gen-ai-v3-hero.jpg');
  position: relative;
  background-position: center;
  background-size: cover;
  padding: 16px 12%;
  padding-top: 64px;

  @media #{$istock-medium-up} {
    height: 496px;
  }

  .badge {
    margin-top: 32px;
    background-color: commonStyles.$true-white;
    color: iStockTheme.$is-black;
    border-radius: 16px;
    padding: 8px 16px;
    display: none;

    @media #{$istock-large-up} {
      margin: 0;
      display: block;
    }
  }

  .title {
    font-size: rem(24);
    color: commonStyles.$true-white;

    @media #{$istock-medium-up} {
      font-size: rem(32);
    }

    @media #{$istock-large-up} {
      font-size: rem(38);
    }
  }

  .subtitle {
    font-size: rem(16);
    color: commonStyles.$true-white;

    @media #{$istock-large-up} {
      font-size: rem(21);
    }
  }

  .searchBarContainer {
    position: absolute;
    width: 95%;
    top: 0;

    @media #{$istock-large-up} {
      height: 1rem; // prevent 'jump' on scroll
      top: 1rem;
    }

    .docked {
      @media #{$istock-medium-up} {
        max-width: 100%;
      }
    }
  }
}
