@use '~@unisporkal/alliance-ui-theme/dist/styles/iStock/index' as iStockTheme;
@use '~@unisporkal/alliance-ui-theme/dist/styles/common/index' as commonStyles;

.container {
  position: relative;
  width: 100%;
  min-height: 495px;
  padding: 64px 10% 24px;
  gap: 1.5rem;
  text-align: center;

  .videoBackground {
    object-fit: cover;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;

    @include commonStyles.medium-and-up {
      width: 100%;
    }
  }

  .title {
    position: relative;
    color: commonStyles.$true-white;
    margin-top: 1em;
    line-height: 1.4;

    @include commonStyles.small-and-up {
      @include iStockTheme.h4-styles;
    }

    @include commonStyles.medium-and-up {
      margin-top: 0;
    }

    @include commonStyles.large-and-up {
      @include iStockTheme.h3-styles;

      margin-top: 0;
    }
  }

  .subtitle {
    position: relative;
    color: commonStyles.$true-white;
    line-height: 1.4;

    @include commonStyles.small-and-up {
      @include iStockTheme.body1-styles;
    }

    @include commonStyles.large-and-up {
      @include iStockTheme.h6-styles;
    }
  }

  .cta {
    position: relative;
    border: 3px solid commonStyles.$true-white;
    color: commonStyles.$true-white;
    margin-top: 2rem;
    white-space: nowrap;
  }

  a.attributionLink {
    position: absolute;
    right: 40px;
    bottom: 30px;
    display: none;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 75%);
    color: iStockTheme.$extra-light-grey;

    &:hover,
    &:focus {
      color: commonStyles.$persian-green;
    }

    @include commonStyles.medium-and-up {
      display: block;
    }

    @include commonStyles.large-and-up {
      span {
        @include iStockTheme.h6-styles;
      }
    }
  }

  .searchBarContainer {
    position: absolute;
    width: 100%;
    top: 0;

    @include commonStyles.large-and-up {
      max-width: 95%;
      height: 1rem; // prevent 'jump' on scroll
      top: 1rem;
    }

    .docked {
      @include commonStyles.medium-and-up {
        max-width: 100%;
      }
    }
  }
}
