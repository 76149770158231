@use '~@unisporkal/alliance-ui-theme/dist/styles/iStock/index' as iStockTheme;
@use '~@unisporkal/alliance-ui-theme/dist/styles/common/index' as commonStyles;

.container {
  position: relative;
  background-image: url('~static_content/istock/free-trial-hero.svg');
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;
  width: 100%;
  min-height: 372px;
  padding: 64px 10% 24px;
  gap: 2.5rem;
  text-align: center;

  @include commonStyles.medium-and-up {
    min-height: 496px;
    padding: 64px 10% 16px;
  }

  .title {
    color: commonStyles.$true-white;
    margin-top: 1em;

    @include commonStyles.medium-and-up {
      margin-top: 0;
    }
  }

  .subtitle {
    color: commonStyles.$true-white;
    line-height: 1.4;

    @include commonStyles.medium-and-up {
      max-width: 750px;
      font-size: 1.25rem;
    }

    a {
      color: commonStyles.$true-white;
      text-decoration: underline;
      white-space: nowrap;

      &:hover,
      &:focus {
        color: commonStyles.$persian-green;
      }
    }
  }

  a.attributionLink {
    position: absolute;
    right: 40px;
    bottom: 30px;
    display: none;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 75%);
    color: iStockTheme.$extra-light-grey;
    font-size: 1rem;

    @include commonStyles.medium-and-up {
      display: block;
    }

    &:hover,
    &:focus {
      color: commonStyles.$persian-green;
    }
  }

  .searchBarContainer {
    position: absolute;
    width: 100%;
    top: 0;

    @include commonStyles.medium-and-up {
      max-width: 95%;
    }

    @include commonStyles.large-and-up {
      height: 1rem; // prevent 'jump' on scroll
      top: 1rem;
    }

    .docked {
      @include commonStyles.medium-and-up {
        max-width: 100%;
      }
    }
  }
}
