@use '@unisporkal/alliance-ui-theme/dist/styles/iStock' as iStockTheme;
@use '@unisporkal/alliance-ui-theme/dist/styles/common/index' as commonStyles;
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_functions';

.container {
  width: 100%;
  height: 12%;
  gap: 16px;
  text-align: center;
  position: relative;
  background-position: center;
  background-size: cover;
  padding: 16px 12%;
  padding-top: 64px;

  @include commonStyles.medium-and-up {
    height: 496px;
  }

  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .searchBarContainer,
  .title,
  .subtitle,
  .link,
  .details {
    position: relative;
    z-index: 1;
  }

  .title {
    font-size: rem(38);
    margin-top: 1em;
  }

  .subtitle {
    font-size: rem(20);
    color: commonStyles.$true-black;
    margin: 1em 0;

    @include commonStyles.large-and-up {
      font-size: rem(21);
    }
  }

  .link {
    color: commonStyles.$true-black;
    border-color: commonStyles.$true-black;
    border: solid;
    border-radius: 5px;
    padding: 1rem 4rem;
    font-weight: 500;
    margin-bottom: 1rem;

    &:hover,
    &:focus {
      background-color: commonStyles.$true-white;
      border-color: commonStyles.$true-white;
    }
  }

  .details {
    position: absolute;
    right: 40px;
    bottom: 30px;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 75%);
    color: iStockTheme.$extra-light-grey;
    font-size: rem(16);
    display: none;

    @include commonStyles.medium-and-up {
      display: block;
    }

    &:hover,
    &:focus {
      color: commonStyles.$persian-green;
      text-decoration: none;
    }
  }

  .searchBarContainer {
    position: absolute;
    width: 95%;
    top: 0;

    @include commonStyles.large-and-up {
      height: 1rem;
      top: 1rem;
    }

    .docked {
      @include commonStyles.medium-and-up {
        max-width: 100%;
      }
    }
  }
}
